import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Button } from "@components/button";
import { useShopifyBooks } from "@utils/useShopifyBooks";
import { Cart } from "@icons";
import cn from "classnames";
import { tagular } from "@cohesion/tagular";
import { ShopListCard } from "./shopListCard";

const transformBook = (book, slug) => {
  const { variants, title, description, images, url } = book;
  const product = variants[0];

  return {
    title: title.split("-")[0].replace("Lonely Planet's ", ""),
    price: `$${product?.price?.amount} USD`,
    description,
    image: images?.[0],
    href: `${url}?utm_source=lonelyplanet&utm_campaign=articlesdriver&utm_content=${slug}`,
  };
};

export function RelatedBooks({ context, className, tagularCtx }) {
  const [allBooks, setAllBooks] = useState([]);
  const {
    handleUpdateCart,
    handleCreateCart,
    books,
    error,
    fauxCart,
    hasQueried,
  } = useShopifyBooks({
    tagularCtx,
    queryURL: "/api/components/article-books",
    body: JSON.stringify(context?.tags),
  });

  useEffect(() => {
    setAllBooks(books);
  }, [books]);

  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      tagular("product_view", {
        product: {
          location: "ARTICLE MODULE_SHOP",
          text: "Explore all books",
          position: 0,
          brand: "shop",
        },
      });
    }
  }, [inView]);

  if (!hasQueried.current) return null;

  return (
    <section className={cn("bg-sand-100 py-10 lg:py-16", className)} ref={ref}>
      <h2 className="text-black mb-6 article-h2">
        Get a book. Get inspired. Get exploring.
      </h2>
      <div className="flex mb-9 lg:mb-14 space-x-4">
        <Button
          className="btn"
          target="_blank"
          rel="noopener noreferrer"
          href="https://shop.lonelyplanet.com/?utm_source=lonelyplanet&utm_campaign=article&utm_content=articlebooks"
          onClick={() =>
            tagular("product_click", {
              actionOutcome: "EXTERNALLINK",
              correlationId: "",
              product: {
                location: "ARTICLE MODULE_SHOP",
                text: "Explore all books",
                position: 0,
                brand: "shop",
              },
              outboundUrl:
                "https://shop.lonelyplanet.com/?utm_source=lonelyplanet&utm_campaign=article&utm_content=articlebooks",
            })
          }
        >
          Explore all books
        </Button>
        <Button
          className="btn btn-primary flex items-center"
          onClick={handleCreateCart}
          disabled={!fauxCart.length || error}
        >
          Cart{fauxCart[0] ? ` (${fauxCart.length})` : ""}
          <Cart className="ml-2" />
        </Button>
      </div>
      <div className="space-y-2 lg:space-y-6">
        {allBooks.slice(0, 3).map(book => (
          <ShopListCard
            key={book.id}
            {...{
              ...transformBook(book, context.slug),
              addToCart: () => handleUpdateCart("add", book.variants[0]),
              removeFromCart: () =>
                handleUpdateCart("remove", book.variants[0]),
              inCart: fauxCart.some(({ id }) => id === book.variants[0].id),
            }}
          />
        ))}
      </div>
    </section>
  );
}

RelatedBooks.propTypes = {
  className: PropTypes.string,
  context: PropTypes.shape({
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    slug: PropTypes.string,
  }),
  tagularCtx: PropTypes.shape({
    location: PropTypes.string,
  }),
};
